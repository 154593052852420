(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
(function ($) {
  $(window).load(function () {
    $( ".blue-hero" ).each(function( index ) {
      // Apply multiply gradient to .blue-hero
      var image_url = $( this ).find(".zn-bgSource-image").css("background-image");
      if(!image_url){
    	image_url = $( this ).css("background-image");
      }
      $( this ).find(".zn-bgSource-image").css("background-image", "linear-gradient(-134deg, #11ACE8 0%, #0B85B5 100%), " + image_url);
      $( this ).find(".zn-bgSource-image").css("background-blend-mode", "multiply");
      $( this ).find(".zn-bgSource-image").css("color", "white");
      $( this ).css("background-image", "linear-gradient(-134deg, #11ACE8 0%, #0B85B5 100%), " + image_url);
      $( this ).css("background-blend-mode", "multiply");
      $( this ).css("color", "white");
    });
  });
})(jQuery);

},{}]},{},[1]);
